<template>
  <div class="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox"  v-if="configs && configs.length">
      <!-- <div class="Official" @click="Official"></div> -->
      <div class="telegram" @click="toContact(tgUrl)" v-if="tgUrl"></div>
      <div class="potato" @click="toContact(potatoUrl)" v-if="potatoUrl"></div>
      <div class="business" @click="toContact(swUrl)" v-if="swUrl"></div>
      <div class="channel" @click="toContact(qdUrl)" v-if="qdUrl"></div>
    </div>
    <div class="main">
      <!-- <div class="figureBox">
        <swiper ref="swiper" :options="swiperOption" class="swiper">
          <swiper-slide class="swiper-slide">
            <div class="swiper-item figure1"></div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-item figure2"></div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-item figure3"></div>
          </swiper-slide>
        </swiper>
      </div> -->
      <div class="downloadArea">
        <!-- <div class="logoText"></div> -->
        <div class="download-box">
          <div class="qrcode-box">
            <div class="qrcode-content">
              <div :class="isCheck ? 'active' : 'unActive'">
                <img class="qrcode" :src="qrImg" alt="" />
              </div>
            </div>
          </div>
          <div class="btn-box">
            <div class="ios-btn"></div>
            <div class="android-btn"></div>
            <div class="recommendTip"></div>
          </div>
        </div>
        <div class="tip"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
export default {
  props: ["qrImg", "os", "configs"],
  watch: {
    configs(newValue) {
      newValue.forEach((item) => {
        if (item.configType == "tg_group") {
          this.tgUrl = item.link;
        } else if (item.configType == "potato_group") {
          this.potatoUrl = item.link;
        } else if (item.configType == "sw_cooperate") {
          this.swUrl = item.link;
        } else if (item.configType == "qd_cooperate") {
          this.qdUrl = item.link;
        }
      });
      return newValue;
    },
  },
  data() {
    return {
      tgUrl: "",
      potatoUrl: "",
      swUrl: "",
      qdUrl: "",
      isCheck: false,
      swiperOption: {
        // 轮播图配置
        initialSlide: 0,
        allowTouchMove: false,
        direction: "horizontal",
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: true, // 循环模式选项
        slidesPerView: 1,
        speed: 1000,
      },
    };
  },
  methods: {
    checkFn() {
      this.isCheck = !this.isCheck;
      setTimeout(() => {
        this.isCheck = !this.isCheck;
      }, 1500);
    },
    toTg() {
      window.location.href = "https://t.me/bense8";
    },
    Official() {
      window.location.href = "https://erosm.co";
    },
    toPotato() {
      window.location.href = "https://chptdl.org/wuyibenseguanfangqun";
    },
    toBusiness() {
      window.open("https://t.me/mu02guang");
    },
    toChannel() {
      window.open("https://t.me/huansong_nangua");
    },
    toContact(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.jpg") no-repeat;
  background-size: 100% 100%;
  .logo {
    height: 117px;
    width: 300px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 48px;
    left: 50px;
  }
  .contactBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 37px;
    right: 66px;
    .Official {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/Official.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      margin-left: 30px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      margin-left: 30px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .business {
      height: 68px;
      width: 68px;
      margin-left: 30px;
      background: url("./../../../assets/images/pc/business.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .channel {
      height: 68px;
      width: 68px;
      margin-left: 30px;
      background: url("./../../../assets/images/pc/channel.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .main {
    // position: fixed;
    // left: 13%;
    // top: 6%;
    // display: flex;
    // align-items: flex-end;
    .figureBox {
      height: 800px;
      width: 676.8px;
      .swiper {
        height: 100%;
        width: 100%;
        .swiper-slide {
          .swiper-item {
            width: 100%;
            height: 100%;
            position: relative;
          }
          .figure1 {
            background: url("../../../assets/images/pc/figure1.webp") no-repeat;
            background-size: 100% 100%;
          }
          .figure2 {
            background: url("../../../assets/images/pc/figure2.webp") no-repeat;
            background-size: 100% 100%;
          }
          .figure3 {
            background: url("../../../assets/images/pc/figure3.webp") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .downloadArea {
      // margin-bottom: 10%;
      // margin-left: 10%;
      position: fixed;
      left: 106px;
      bottom: 55px;
      .logoText {
        height: 196.5px;
        width: 577px;
        background: url("./../../../assets/images/pc/logoText.png") no-repeat;
        background-size: 100% 100%;
      }
      .download-box {
        width: 577px;
        height: 270px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        .qrcode-box {
          display: flex;
          flex-direction: column;
          width: 270px;
          height: 270px;
          background-color: white;
          border-radius: 20px;
          justify-content: center;
          align-items: center;
          .qrcode-content {
            width: 270px;
            height: 270px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            overflow: hidden;

            .qrcode {
              width: 250px;
              height: 250px;
              background-color: #fff;
            }
            .active {
              animation: activeActive 2s;
              -webkit-animation: activeActive 2s; /* Safari 与 Chrome */
              animation-timing-function: linear;
              -webkit-animation-timing-function: linear;
            }
            @keyframes activeActive {
              0% {
                outline: 0;
              }
              50% {
                outline: 10px solid #fff;
              }
              100% {
                outline: 0;
              }
            }
          }
        }
        .btn-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 240px;
          margin-left: 30px;

          .ios-btn {
            width: 324.8px;
            height: 67.8px;
            cursor: pointer;
            background: url("../../../assets/images/pc/iosBtn.png") no-repeat;
            background-size: 100% 100%;
          }
          .android-btn {
            width: 324.8px;
            height: 67.8px;
            cursor: pointer;
            background: url("../../../assets/images/pc/androidBtn.png")
              no-repeat;
            background-size: 100% 100%;
          }
          .recommendTip {
            height: 28px;
            width: 252px;
            background: url("../../../assets/images/pc/ucBtn.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .tip {
        margin-left: -50px;
        width: 100%;
        height: 40px;
        background: url("../../../assets/images/pc/tip.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 58.5px;
      }
      // .androidB-btn:hover {
      //   background: url("../../../assets/images/pc/androidBtnFocus.png")
      //     no-repeat;
      //   background-size: 100% 100%;
      // }
    }
  }
}
</style>
