<template>
  <div class="container" @click="doCopy()">
    <mobile
      v-if="!isPC"
      class="mobile"
      :os="os"
      @getApkInfo="handleGetApkInfo"
      :configs="configs"
      :ip="ip"
      :switchData="switchData"
      :loading="loading"
      style="height:100%"
    ></mobile>

    <tips
      v-else-if="isPC && page_pc_url === 'https://sisqd.com/?dc=THD2'"
    ></tips>
    <pc
      v-else
      class="pc"
      :qr-img="QRImg"
      :os="os"
      @getApkInfo="handleGetApkInfo"
      :configs="configs"
    ></pc>
    <!-- loading-->
    <div class="loading_box" v-if="loading && !isPC">
      <div style="height: 5px"></div>
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3>加载中...</h3>
    </div>
    <selectLinePopup
      :apiUrls="apiUrls"
      :showOverlay="showOverlay"
      @selectLine="selectLine"
    ></selectLinePopup>

  </div>
</template>
<script>
import getApkInfo from "@/api/getApkInfo";
import getButtonlist from "@/api/getTemplate";
import userAgent from "@/utils/userAgent";
import QRCode from "qrcode";
import axios from "@/utils/request.js";
import mobile from "./components/mobile";
import pc from "./components/pc";
import tips from "./components/tips";
import selectLinePopup from "./components/selectLinePopup";
export default {
  components: {
    mobile,
    pc,
    tips,
    selectLinePopup
  },
  data() {
    return {
      type: "",
      QRImg: "",
      os: "",
      param: "",
      cutInfo: {}, //替换url参数对象内<key> dc内容;
      ip: "",
      switchData: {},
      page_pc_url: "",
      iosDownUrl: "",
      androidDownUrl: "",
      loading: false,
      time: 1, // 请求时间
      timer: null, // 定时器
      resData: undefined, // 响应数据
      apiUrls: JSON.parse(process.env.VUE_APP_BASE_HOSTS), // api数组
      showOverlay: false, // 显示选线弹窗
      configs: [],
    };
  },

  computed: {
    isPC() {
      return this.os.isPc;
    }
  },
  created() {
    this.getPcUrl();
    this.os = userAgent();
    this.functionParam();
    this.getButtonlist();
    // this.getIp();
  },
  mounted() {
    this.getQRImg();
  },
  methods: {
    getPcUrl() {
      this.page_pc_url = window.location.href;
    },
    //1.判断进来的url地址 替换到需要手动设置的参数 如cd
    functionParam() {
      this.param =
        JSON.stringify(this.$route.query) === "{}"
          ? "{}"
          : JSON.stringify(this.$route.query);
    },
    //2.获取按钮状态信息
    async getButtonlist() {
      try {
        let param_btn_list = {
          appId: 24,
          channel: this.param,
          domain: window.location.protocol + "//" + window.location.host
        };
        const res = await getButtonlist(param_btn_list);
        this.resData = res;
        this.loading = false;
        if (res.data.code === 200) {
          // this.switchData = res.data.data;
          this.configs = res.data.data.configs || [];
          this.androidDownUrl = res.data.data.androidDownUrl;
          this.iosDownUrl = res.data.data.iosDownUrl;
        }
      } catch (err) {
        this.showOverlay = true;
        console.log(err);
      }
    },
    //3. 获取外网IP地址
    getIp() {
      axios.get("https://api.ipify.org/?format=json").then(res => {
        this.ip = res.data.ip;
      });
    },
    //4. 点击下载按钮获取apk信息并下载
    async handleGetApkInfo(type) {
      await this.doCopy();
      let terminal;
      let packageType;
      if (type === "ios") {
        terminal = "ios";
        packageType = 1;
      } else {
        terminal = "android";
      }
      const param = {
        appId: 24,
        ip: this.ip ? this.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(this.param),
          ...this.cutInfo
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal,
        packageType
      };
      if (terminal === "android") {
        window.location.href = this.androidDownUrl;
      } else {
        let dc = this.$route.query.dc;
        let ch = this.$route.query.ch;
        let pc = this.$route.query.pc;
        let linkUrl = "";
        if (dc) {
          linkUrl = this.iosDownUrl + "?dc=" + dc;
        } else if (ch) {
          linkUrl = this.iosDownUrl + "?ch=" + ch;
        } else if (pc) {
          linkUrl = this.iosDownUrl + "?pc=" + pc;
        } else {
          linkUrl = this.iosDownUrl;
        }
        window.location.href = linkUrl;
      }
      getApkInfo(param);
    },
    //5.生成二维码
    getQRImg() {
      const color = this.type === "xingba" ? "#fb4b15" : "";
      QRCode.toDataURL(window.location.href, {
        width: 240,
        margin: 1,
        maskPattern: 1,
        scale: 1,
        color: {
          dark: color,
          light: "rgba()"
        }
      }).then(url => {
        const regex = /code=/gi;
        if (url.indexOf("code=") != -1) {
          this.QRImg = url.replace(regex, "pc=");
        } else {
          this.QRImg = url;
        }
      });
    },
    // 6.剪贴板
    doCopy() {
      let req = JSON.stringify(this.$route.query);
      this.$copyText(req).then(
        () => {
          console.log("复制成功");
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    // 选线
    selectLine(item) {
      axios.defaults.baseURL = item; // 更改apiURL
      setTimeout(() => {
        this.showOverlay = false;
        this.loading = true;
        this.getButtonlist(); // 重新请求接口
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  overflow: hidden;
  height: 100%;
  .mobile {
    height: 100%;
  }
}
// @media screen and (min-width: 750px) {
//   .mobile {
//     display: none;
//   }
//   .pc {
//     overflow: hidden;
//   }
// }
// @media screen and (max-width: 750px) {
//   .pc {
//     display: none;
//   }
// }
/* loading */
.loading_box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  border-radius: 20px;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    padding-top: 10px;
    font-size: 20px;
    color: #fff;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//loading
</style>
